import {ethers} from "ethers";


export const PROD_CONTRACT_ADDR = "0x39b92b817642B33b4898812A8A1fEa287A783513";
// export const PROD_CONTRACT_ADDR = "0x14f74fC74C98c199B842C837D99BE0A9465b4989";

export const ethProvider = window.ethereum && new ethers.providers.Web3Provider(window.ethereum);

export const getContractSigner = () => {
    const PROD_CONTRACT_ABI = require("./abis/main_contract_abi.json");

    const signer = ethProvider.getSigner();
    return new ethers.Contract(PROD_CONTRACT_ADDR,
        PROD_CONTRACT_ABI, signer);
};
