import {useEffect, useMemo, useRef, useState} from "react";
import {getContractSigner, getGMContractSigner} from "./contractUtils";
import Button from "react-bootstrap/Button";

export function GhostMintPage({ currentEthAccount }) {
    const [currentTokensOwned, setCurrentTokensOwned] = useState(null);
    const [mintLive, setMintLive] = useState(true);

    const [currentMintStatus, setCurrentMintStatus] = useState(null);
    const [currentMintStatusSuccess, setCurrentMintStatusSuccess] = useState(true);


    const claimToken = async () => {
        const contract = getContractSigner();
        setCurrentMintStatus("");
        try {
            // calculate final mint value
            await contract.claim();
        } catch (err) {
            setCurrentMintStatus("Contract error: " + err.error.message);
            setCurrentMintStatusSuccess(false);
            return;
        }

        setCurrentMintStatus("Minted successfully! you should receive your tokens shortly!");
        setCurrentMintStatusSuccess(true);
    }

    const fetchContractMintingDetails = async () => {
        const contract = getContractSigner();

        const [localMintLive, contractTokensOwned] = await Promise.all([
            contract.isMintLive(),
            contract.balanceOf(currentEthAccount),
        ]);
        setCurrentTokensOwned(contractTokensOwned.toNumber());

        setMintLive(localMintLive);
    }

    useMemo(async () => {
        await fetchContractMintingDetails();
    }, [currentEthAccount]);

    const MintingDetails = () => {
        return (
            <div id="pharmacy_mint_minting_details">
                Mint Status: {mintLive ? "Live" : "Paused"}
                <br/>
                Already Claimed: {currentTokensOwned > 0 ? "Yes" : "No"}
            </div>
        );
    }

    const MintingControls = () => {
        return (
            <div className="primary-controls">
                {mintLive && !currentTokensOwned && <Button variant="primary" className="btn btn-lg btn-theme" onClick={claimToken}>Claim!</Button>}
            </div>
        );
    }

    const MintStatus = () => {
        return (
            <div className={currentMintStatusSuccess ? "status_success" : "status_error"}>
                {currentMintStatus}
            </div>
        );
    }

    return <div className="main-content">
        <MintingDetails/>
        <MintingControls />
        <MintStatus />
    </div>;
}
