import 'bootstrap/dist/css/bootstrap.min.css';

import '../css/App.css'; // this needs to be below the bootstrap import to overwrite styles
import {Link, Route, Routes} from "react-router-dom";
import 'react-dropdown/style.css';
import {GhostMintPage} from "./mintPage";
// import {BBOwnersPage} from "./mainPage";

// import gmoneyImage from "../img/punk8219.png";
import {useEffect, useState} from "react";
import {ethProvider, getContractSigner, PROD_CONTRACT_ADDR} from "./contractUtils";
import Button from "react-bootstrap/Button";
// import {BBReplayPage} from "./replayPage";
// import {BrickBreakersTopChartsPage} from "./topChartsPage";
import topImage from "url:../img/ghost.gif";


function App() {
    const [currentEthAccount, setCurrentEthAccount] = useState(null);
    const [currentErrMessage, setCurrentErrMessage] = useState(null);

    useEffect(async () => {
        if (!ethProvider) {
            return;
        }

        if (currentEthAccount) {
            return;
        }

        const getAccountIfPossible = async () => {
            const accounts = await ethProvider.listAccounts();
            return accounts.length > 0 ? accounts[0] : null;
        }

        let account = await getAccountIfPossible();
        if (account !== null) {
            setCurrentEthAccount(account);
        }
    })

    const connectToWallet = async () => {
        if (!window.ethereum) {
            setCurrentErrMessage("Error: MetaMask extension missing, please install to proceed....");
            return;
        }

        const ethAccounts = await window.ethereum.request({method: "eth_requestAccounts"});
        setCurrentEthAccount(ethAccounts[0]);
    }

    const VerifiedContractDetails = () => {
        let fixer = PROD_CONTRACT_ADDR;
        return (
            <div id="pharmacy_verified_contract_details">
                { currentEthAccount &&
                    <div id="pharmacy_mint_minting_details_footer">
                        Connected wallet address:&nbsp;
                        <span className="no-upper-case">{currentEthAccount}</span>
                    </div>
                }
                Verified Smart Contract Address: <a href={`https://etherscan.io/address/${fixer}`} target="_blank" rel="noreferrer">{fixer}</a>
                <img id="etherscan_logo" src="https://etherscan.io/images/brandassets/etherscan-logo-circle.png" alt="Etherscan"></img>
            </div>
        );
    }

    return (
        <div className="App">
            <div id="main_container">
                <div className={"header-img-container"} id="pharmacy_img">
                    <img className={"header-img1"} src={topImage} alt="BrickBreaker mint page!"/>
                </div>
                {/*<BBHeader/>*/}
                {/*<BBMenu/>*/}
                {!currentEthAccount && <div className="primary-controls"><Button variant="primary" className="btn btn-primary btn-lg btn-theme" onClick={connectToWallet}>Connect MetaMask Wallet</Button></div>}
                <div className="status_error">{currentErrMessage}</div>
                {currentEthAccount && <Routes>
                    <Route path="/" element={<GhostMintPage currentEthAccount={currentEthAccount} />} />
                    {/*<Route path="/" element={<BBOwnersPage currentEthAccount={currentEthAccount} />} />*/}
                    {/*<Route path="/Replay" element={<BBReplayPage currentEthAccount={currentEthAccount} />} />*/}
                    {/*<Route path="/Scoreboard" element={<BrickBreakersTopChartsPage currentEthAccount={currentEthAccount} />} />*/}
                </Routes>}
                <VerifiedContractDetails />
            </div>
        </div>
    );
}

// https://discord.gg/bq257gbFv

function BBMenu() {
    return (
        <div className="main_nav">
            <nav>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/Mint">Mint</Link>
                    </li>
                    {/*<li>*/}
                    {/*    <Link to="/Scoreboard">Top Scores</Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <Link to="/Replay">Replay</Link>*/}
                    {/*</li>*/}
                </ul>
            </nav>
        </div>
    );
}

function BBHeader() {
    return (
        <div id="header">
            <div className="top_logo">
                <img src={gmoneyImage} alt="" className="top_logo_img"/>
                <div className="top_logo_text">BB</div>
            </div>
            <div id="ms_social_icons_top">
                <div id="ms_social_icons_top_twitter">
                    <a href="https://twitter.com/GM420_Labs" target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="3rem" height="4rem" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                        </svg>
                    </a>
                </div>
                <div id="ms_social_icons_top_discord">
                    <a href="https://discord.gg/bq257gbF" target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="3rem" height="4rem" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    );
}


export default App;
